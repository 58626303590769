import { defineStore } from 'pinia'
import { results2020 } from '../data/2020.js'
import { results2021 } from '../data/2021.js'
import { results2022 } from '../data/2022.js'
import { results2023 } from '../data/2023.js'

export const useDataStore = defineStore({
    id: 'dataStore',
    state: () => ({
        allYears: {
            "2023": results2023,
            "2022": results2022,
            "2021": results2021,
            "2020": results2020
        },
        currentPage: 1,
        year: "2023",
        catAmount: 5,
        reRenderKey: 0
    }), 
    actions: {
        increment(){
            this.currentPage++;
        },
        decrement(){
            this.currentPage--;
        },
        changeYear(yearArg){
            this.currentPage = 1;
            this.year = yearArg;
            this.catAmount = this.allYears[this.year].categories.length;
            this.reRenderKey += 1;
        },
    },
    getters: {
        currentYear(){
            return this.allYears[this.year];
        },
        pages(){
            return this.allYears[this.year].categories
        }
    }
})
