<template>
    <v-btn 
        :disabled="this.dataStore2.currentPage === 1 ? true : false" 
        class="mx-2" 
        color="primary" 
        @click="decrement(); $vuetify.goTo(0);">
        Prev
    </v-btn>
</template>

<script>
import { useDataStore } from "../stores/currentData.js"
export default {
    name: "prevButton",
    data(){
        return {
            dataStore2: useDataStore()
        }
    },
    methods: {
        decrement(){
            this.dataStore2.decrement()
        }
    }
}
</script>
