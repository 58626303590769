<template>
  <v-app id="inspire">

    <v-navigation-drawer
      width="180"
      v-model="drawer"
      app
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            CA Survey
          </v-list-item-title>
          <v-list-item-subtitle>
            Select a year below
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-card class="mx-auto ma-4">
        <v-list>
          <v-list-item
            class="text-center"
            v-for="i in Object.keys(dataStore.allYears)"
            :key="i"
            link
            @click="$vuetify.goTo(0); dataStore.changeYear(i)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ i }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

    </v-navigation-drawer>
  
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        {{ dataStore.year }} 🍺
      </v-toolbar-title>
      
      <v-layout class="justify-end">
        <prevButton></prevButton>
        <nextButton></nextButton>
      </v-layout>
    </v-app-bar>

    <v-main>
      <v-container fluid fill-height>
        <v-row>
          <v-col align="center">
            <v-card width="80%">
              <v-card-title class="text-h3 justify-center">
                {{ titleCase(currentPageName()) }}
              </v-card-title>
            </v-card>
            </v-col>
          </v-row>
        <chartContainer></chartContainer>
      </v-container>

    </v-main>
  </v-app>
</template>

<script>
  import chartContainer from './views/chart.vue';
  import prevButton from './components/prevButton.vue';
  import nextButton from './components/nextButton.vue';
  import { useDataStore } from './stores/currentData.js'

  export default {
  name: 'App',
  props: {
  
  },
  setup(){

  },
  data(){
    return {
      right: null,
      drawer: false,
      nextDisabled: false,
      dataStore: useDataStore()
    }
  },
  components: {
    chartContainer,
    prevButton,
    nextButton,
  },
  computed: {
  },
  methods: {
    currentPageName(){
      return this.dataStore.currentYear.categories[this.dataStore.currentPage-1]
    },
    titleCase(str) {
      return str.replace("_"," ").toLowerCase().split(' ').map(function(word) {
        return word.replace(word[0], word[0].toUpperCase());
      }).join(' ');
    }
  }
}
</script>
