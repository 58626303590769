<template>
    <v-btn 
        :disabled="this.dataStore3.currentPage === this.dataStore3.catAmount ? true : false" 
        class="mx-2" 
        color="primary" 
        @click="increment(); $vuetify.goTo(0);">
        Next
    </v-btn>
</template>

<script>
import { useDataStore } from "../stores/currentData.js"
export default {
    name: "nextButton",
    data(){
        return {
            dataStore3: useDataStore()
        }
    },
    methods: {
        increment(){
            this.dataStore3.increment()
        }
    }
}
</script>
