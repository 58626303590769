export let results2020 = {
    categories: ["demographics","fun","drinking","medical","drug_Use"],
    charts: [{
        demographics: {
            Did_you_take_the_survey_last_year2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','Unsure','Yes'],
                    },
                    title: {
                    text: "Did you take the survey last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [719,114,132]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Subreddit_activity2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Active poster','Discord/Chat only','Lurker'],
                    },
                    title: {
                    text: "Subreddit activity?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [156,16,793]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_long_have_you_been_reading_r_CA__Years_2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0 Years','1 Year','2 Years','3 Years','4 Years','5 Years','6 Years','7 Years','8 Years','9 Years'],
                    },
                    title: {
                    text: "How long have you been reading r/CA?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [216,176,202,125,73,67,33,32,24,17]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_old_are_you__years_2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "100%",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['5','6','15','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','60','62','66','67','72','84'],
                    },
                    title: {
                    text: "How old are you?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,1,1,2,8,12,18,25,36,40,42,54,46,51,43,54,51,48,41,36,52,33,19,31,19,26,23,26,12,10,7,10,12,5,6,4,9,7,8,7,3,5,1,7,5,2,1,1,2,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Height2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "100%",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['4’ 5 in or lower (135cm)','4 ft 10 in (147 cm)','4 ft 11 in (150 cm)','5 ft (152 cm)','5 ft 1 in (155 cm)','5 ft 2 in (157 cm)','5 ft 3 in (160 cm)','5 ft 4 in (162 cm)','5 ft 5 in (165 cm)','5 ft 6 in (168 cm)','5 ft 7 in (170 cm)','5 ft 8 in (173 cm)','5 ft 9 in (175 cm)','5 ft 10 in (178 cm)','5 ft 11 in (180 cm)','6 ft (183 cm)','6 ft 1 in (185 cm)','6 ft 2 in (188 cm)','6 ft 3 in (190 cm)','6 ft 4 in (193 cm)','6 ft 5 in (196 cm)','6 ft 6 in (198 cm)','6 ft 7 in (201 cm)','7 ft or higher (213 cm)'],
                    },
                    title: {
                    text: "Height?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [0,1,2,10,14,35,50,47,56,60,77,86,80,101,88,99,55,38,31,15,6,4,2,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Gender2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Female','Female (Trans)','Male','Male (Trans)','Non-binary'],
                    },
                    title: {
                    text: "Gender?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [365,5,562,11,19]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Sexual_Orientation2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Asexual','Bisexual','Dont know don’t care','Fuck the system','Gay','I’m really not sure mostly straight I guess','Other','Pansexual','Queer','Straight','Undecided','Unsure'],
                    },
                    title: {
                    text: "Sexual Orientation"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [17,174,1,1,43,1,7,27,1,688,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Which_country_do_you_live_in2020: {
                chartOptions: {
                    type: "map",
                    mapChart: "mapChart",
                    class: "map",
                    size: "6",
                    chart: {
                    map: "myMapName"
                    },
                    title: {
                    text: "Which country do you live in?"
                    },
                    subtitle: {
                    text:
                        ''
                    },
                    mapNavigation: {
                    enabled: true,
                    buttonOptions: {
                        alignTo: "spacingBox"
                    }
                    },
                    colorAxis: {
                    min: 0
                    },
                    series: [
                    {
                        name: "Count",
                        states: {
                        hover: {
                            color: "#BADA55"
                        }
                        },
                        dataLabels: {
                        enabled: true,
                        format: "{point.name}"
                        },
                        allAreas: false,
                        data: [["us",675],["ca",84],["gb",56],["au",29],["de",13],["ie",13],["at",7],["fi",7],["se",7],["jp",6],["no",6],["fr",5],["nl",5],["nz",5],["kr",5],["br",3],["dk",3],["il",3],["gr",2],["ru",2],["tr",2],["af",1],["ar",1],["az",1],["bs",1],["by",1],["be",1],["bg",1],["cr",1],["hr",1],["cz",1],["ee",1],["hn",1],["in",1],["pl",1],["rs",1],["sk",1],["es",1],["ch",1],["th",1],["uz",1],["zw",1]]
                    }
                    ]
                }
            },
            If_you_re_in_the_USA_or_Canada__which_state_or_province_are_you_located_in2020: { 
                    chartOptions: {
                    type: "map",
                    mapChart: "mapChart",
                    class: "map",
                    size: "6",
                    chart: {
                    map: "NaCan"
                    },
                    title: {
                    text: "If you're in the USA or Canada, which state or province are you located in?"
                    },
                    subtitle: {
                    text:
                        ''
                    },
                    mapNavigation: {
                    enabled: true,
                    buttonOptions: {
                        alignTo: "spacingBox"
                    }
                    },
                    colorAxis: {
                    min: 0
                    },
                    series: [
                    {
                        name: "Count",
                        states: {
                        hover: {
                            color: "#BADA55"
                        }
                        },
                        dataLabels: {
                        enabled: true,
                        format: "{point.name}"
                        },
                        allAreas: false,
                        data: [["us-al",9],["us-ak",4],["ca-ab",11],["us-az",15],["us-ar",3],["ca-bc",18],["us-ca",67],["us-co",16],["us-ct",10],["us-de",3],["us-va",25],["us-fl",33],["us-ga",21],["us-id",2],["us-il",32],["us-in",11],["us-ia",4],["us-ks",4],["us-ky",7],["us-la",3],["us-me",6],["ca-mb",4],["us-md",21],["us-ma",17],["us-mi",18],["us-mn",20],["us-mo",13],["us-mt",5],["us-ne",6],["us-nv",4],["ca-nb",1],["us-nh",4],["us-nj",18],["us-nm",6],["us-ny",27],["us-nc",15],["us-nd",2],["ca-nt",2],["ca-ns",5],["us-oh",28],["us-ok",7],["ca-on",32],["us-or",9],["us-pa",38],["ca-pe",1],["ca-qc",5],["us-ri",4],["ca-sk",2],["us-sc",8],["us-sd",1],["us-tn",6],["us-tz",45],["us-ut",7],["us-vt",3],["us-wa",31],["us-wv",8],["us-wi",11],["us-wy",3],["ca-yt",1]]
                    }
                    ]
                }
            },
            Education2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['Did not graduate high school','High school or equivalent','Some college or university','College or university graduate','Some graduate school or continuing education','Completed graduate school or other continuing education','Trade school graduate'],
                    },
                    title: {
                    text: "Education"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [30,95,291,345,52,129,18]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Religion2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['Atheist','Agnostic','Christianity','Not sure/Undecided','Spiritual','Buddhism','Judaism','Neopaganism','Other','Wicca','Taoism','Islam','Catholic','Agnostic Christian','All and None','Other'],
                    },
                    title: {
                    text: "Religion"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [295,284,151,91,58,19,10,10,8,8,4,3,2,15]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Ethnicity2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['White','Mixed','Hispanic','Asian','Black','Native American','Ashkenazi Jew','Halfer-white and inuit','Inuit','Kinda Gook Honkey Redhead','Latino','Middle eastern','N/A','Pacific Islander','Slavic','South Asian','White and Asian'],
                    },
                    title: {
                    text: "Ethnicity"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [834,50,26,17,11,8,1,1,1,1,1,1,2,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_been_arrested_this_year2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0 Times','1 Time','2 Times','3 Times','5 Times'],
                    },
                    title: {
                    text: "How many times have you been arrested this year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [895,47,12,4,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_been_to_jail_this_year2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0 Times','1 Time','2 Times','3 Times','10+ Times'],
                    },
                    title: {
                    text: "How many times have you been to jail this year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [921,32,4,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Was_the_last_time_a_drinking_related_offense2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','No but alcohol was involved','Yes'],
                    },
                    title: {
                    text: "Was the last time a drinking-related offense?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [77,60,141]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Are_you_licensed_to_drive_currently2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No (never was)','No (used to be)','Yes'],
                    },
                    title: {
                    text: "Are you licensed to drive currently?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [6,120,77,757]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            If_you_answered__no__used_to_be____was_this_DUI_related2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','Yes'],
                    },
                    title: {
                    text: "If you answered 'no (used to be)', was this DUI-related?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [74,52]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Marital_Status2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Single','Committed Relationship','Engaged','Married','Separated','Divorced'],
                    },
                    title: {
                    text: "Marital Status"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [423,237,31,218,15,36]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            If_you_re_married_or_in_a_committed_relationship__is_your_partner_s_a_CA2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','They don’t drink','They drink but aren’t an alcoholic','Alcoholic','CA'],
                    },
                    title: {
                    text: "If you're married or in a committed relationship, is your partner/s a CA?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [429,101,334,71,21]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Your_upbringing_was_or_your_parents_were____2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Adopted','Homeless','It’s complicated','Married','Never Married','Raised by grandparents/other family','Raised by single father','Raised by single mother','Separated','Ward of the court'],
                    },
                    title: {
                    text: "Your upbringing was or your parents were...."
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [13,1,53,610,25,15,10,86,145,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_children_do_you_have2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5','7','9'],
                    },
                    title: {
                    text: "How many children do you have?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [752,90,73,29,8,4,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_want_to_have_a_another_child__now_or_in_the_future2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','Unsure/haven’t decided','Yes'],
                    },
                    title: {
                    text: "Do you want to have a/another child, now or in the future?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [506,220,232]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            What_industry_do_you_work_in2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['Accounting','Admin - clerical','Admin - legal','Art/creative','Automotive','Banking','Construction','Design','Distribution - shipping','Education','Engineering','Entertainment','Executive','Finance','General Business','General labor','Government','Health Care','Hospitality','Human resources','Information technology','Installation/maintenance/repair','Legal','Management','Manufacturing','Marketing','Media/journalism/newspaper','Millitary','None','Nonprofit/social services','Pharmaceutical','QA/quality control','Real estate','Research','Restaurant/food service','Retail','Retired','Sales','Science','Skilled labor/trades','Veterinary services'],
                    },
                    title: {
                    text: "What industry do you work in?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [14,18,2,24,5,9,38,3,17,51,21,17,2,21,16,18,31,61,33,4,60,3,24,7,24,13,11,14,153,15,6,5,5,15,73,39,6,20,18,38,3]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Did_you_lose_or_gain_a_job_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','Gained a job','Lost a job','Both','Neither (stayed employed)','Neither (stayed unemployed)'],
                    },
                    title: {
                    text: "Did you lose or gain a job in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [47,120,98,150,452,92]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            If_you_lost_it_in_the_last_year__do_you_believe_it_s_related_to_drinking2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Definitely not','Probably not','Possibly','Probably','Definitely'],
                    },
                    title: {
                    text: "If you lost it in the last year, do you believe it's related to drinking?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [114,23,35,24,74]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_money__in_USD__did_you_earn_last_year2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['$0-$10000','$10001-$20000','$20001-$30000','$30001-$40000','$40001-$50000','$50001-$60000','$60001-$70000','$70001-$80000','$80001-$90000','$90001-$100000','$100000+','Decline to answer','N/A'],
                    },
                    title: {
                    text: "How much money (in USD) did you earn last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [94,123,104,103,93,48,56,40,27,27,93,89,61]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_actively_save_money_in_a_savings_account_or_emergency_fund2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Do you actively save money in a savings account or emergency fund?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [24,375,560]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Are_you_covered_by_health_insurance2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','Partial Coverage','Yes'],
                    },
                    title: {
                    text: "Are you covered by health insurance?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [157,55,747]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Are_you_enrolled_as_a_student2020: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','Yes','Yes and I work'],
                    },
                    title: {
                    text: "Are you enrolled as a student?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [789,91,78]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
        },
        fun: {
            How_often_do_you_shower_bathe2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Seldom','When there’s a reason to/"as needed"','Only days I work','Every other day','Once a day','Multiple times a day'],
                    },
                    title: {
                    text: "How often do you shower/bathe?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [18,91,27,185,360,54]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_brush_your_teeth2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['None','Seldom','Once a day','Almost every day','Twice a day'],
                    },
                    title: {
                    text: "How often do you brush your teeth?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [7,112,351,156,277]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_floss_your_teeth2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['None','Seldom','A few times a week','Almost every day','Every day'],
                    },
                    title: {
                    text: "How often do you floss your teeth?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [212,382,155,65,89]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_drink_coffee_regularly2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['I don’t drink coffee','Seldom','A few times a month','A few times a week','Almost every day','Every day'],
                    },
                    title: {
                    text: "Do you drink coffee regularly?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [129,101,83,93,140,357]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_languages_do_you_speak_fluently2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5','6','10+'],
                    },
                    title: {
                    text: "How many languages do you speak fluently?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [709,146,34,9,2,2,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_wear_glasses_or_contact_lenses2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                        categories: ['Both','Contacts','Glasses','Neither'],
                    },
                    title: {
                        text: "Do you wear glasses or contact lenses?"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [105,64,334,400]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                }
            },
            Do_you_have_any_pets2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '12',
                    height: ".9vh",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['Cat/s','Dog/s','None','Fish','Reptile/s','Bird/s','Snake/s','Guinea Pig/s','Horse/s','Rabbit/s','Gerbil/s','Spider/s','Depression','Ferrets','Goat/s','Chicken/s','Hamster','Other'],
                    },
                    title: {
                    text: "Do you have any pets?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [337,331,305,38,26,21,13,8,6,6,4,3,2,2,2,2,2,17]
                    }
                    ],
                    legend: {
                        enabled: false
                    }
                }
            },
            Which_sports_did_you_follow_this_year2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['None','Football','Basketball','Baseball','Hockey','Soccer (USA)/Football (UK)','MMA','E-Sports','Boxing','Formula 1','Wrestling','Tennis','Rugby','Cricket','Cycling','Golf','Moto GP','Lacrosse','Ice Skating','Surfing','Volleyball','Darts','Running','Badminton','BMX','Disc Golf','Motorcycle racing','Ultimate Frisbee','Weightlifting','Winter Sports','Americas cup','Billiards','Chess','chicken/ss','Climbing','College softball','Competetive Blackouts','Equestrian sports','Gymkhana','Karate','Marble league on YouTube. Seriously!','Quidditch','Rugby League','Skateboarding','Snooker','Sumo','Swimming','UFC'],
                    },
                    title: {
                    text: "Which sports did you follow this year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [497,237,104,89,88,66,59,46,34,33,24,18,15,11,11,8,8,6,5,5,5,4,4,2,2,2,2,2,2,2,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
                    }
                    ],
                    legend: {
                        enabled: false
                    }
                }
            },
            Do_you_consider_yourself_a_violent_person2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['No','Yes'],
                    },
                    title: {
                    text: "Do you consider yourself a violent person?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [851,52]
                    }
                    ],
                    legend: {
                        enabled: false
                    }
                }
            },
            Did_you_get_into_a_physical_fight_in_20192020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['No','Yes'],
                    },
                    title: {
                    text: "Did you get into a physical fight in 2019?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [794,109]
                    }
                    ],
                    legend: {
                        enabled: false
                    }
                }
            },
            Do_you_own_a_gun_multiple_guns2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['No','Yes'],
                    },
                    title: {
                    text: "Do you own a gun/multiple guns?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [696,207]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_shot_it_them_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Have you shot it/them in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [329,180,128]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Here_we_are_again____poop__What_s_your_normal_consistency2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Type 1','Type 2','Type 3','Type 4','Type 5','Type 6','Type 7'],
                    },
                    title: {
                    text: "Here we are again... poop. What's your normal consistency?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [17,24,129,324,126,195,62]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            What_type_of_device_do_you_use_the_MOST_for_surfing_the_internet2020: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Desktop','Gaming console','Laptop','Phone','Smart TV','Tablet'],
                    },
                    title: {
                    text: "What type of device do you use the MOST for surfing the internet?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [113,2,133,615,1,39]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
        },
        drinking: {
            Do_you_consider_yourself_an_alcoholic2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','Possibly','Yes between crippling and high functioning','Yes crippling alcoholism','Yes high functioning','Yes in recovery'],
                    },
                    title: {
                    text: "Do you consider yourself an alcoholic?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [49,85,240,63,256,104]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Are_you_drinking_RIGHT_now2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','Yes'],
                    },
                    title: {
                    text: "Are you drinking RIGHT now?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [492,305]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            At_what_age_did_you_have_your_first_drink__numbers_only_2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','25','26','27','29','30','32'],
                    },
                    title: {
                    text: "At what age did you have your first drink?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [3,2,4,11,8,4,13,9,19,26,84,118,117,102,91,70,56,25,17,8,2,2,1,1,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            At_what_age_did_you_start_drinking_reulgarly2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['6','9','10','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','35','39','40','47','100'],
                    },
                    title: {
                    text: "At what age did you start drinking reulgarly?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,1,2,5,13,27,49,90,93,131,86,60,91,46,23,17,13,9,9,6,5,4,2,3,2,5,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            At_what_age_did_you_become_physically_dependent_on_alcohol2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','7','9','11','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','40','41','42','43','44','45','47','50','54','100'],
                    },
                    title: {
                    text: "At what age did you become physically dependent on alcohol?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [15,1,1,1,1,3,7,15,35,40,49,86,50,46,35,64,33,34,33,16,32,9,12,11,7,22,5,4,4,4,4,6,4,2,4,2,1,1,7]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_drinks_do_you_have_per_day2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "100%",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','0.5','0.75','1','1.2','1.3','1.5','2','2.4','2.5','3','3.25','3.5','3.57','4','4.2','4.25','4.3','4.5','5','5.1','5.3','5.5','6','6.5','7','7.14','7.29','7.5','7.8','7.9','8','8.4','8.5','8.8','9','9.5','10','10.1','10.2','10.25','10.8','11','11.3','11.5','12','12.1','12.5','12.675','12.75','13','13.3','13.5','14','14.3','14.5','14.7','15','15.3','15.5','16','16.5','16.7','16.9','17','17.5','18','18.5','19','19.1','19.7','19.75','20','21','21.25','22','23','24','25','27','27.5','29','30','33','33.75','33.8','35','36','37','47','50','58','75','77','3-7','4-9'],
                    },
                    title: {
                    text: "How many drinks do you have per day?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [121,3,1,11,1,1,3,16,1,3,18,1,4,1,36,2,3,1,4,37,1,1,1,41,1,30,1,1,9,1,2,52,1,25,4,13,2,51,1,2,1,1,8,2,1,62,1,3,1,5,5,2,2,10,1,1,1,41,1,1,8,4,7,21,6,1,6,1,2,1,1,1,25,1,3,3,1,1,8,1,1,1,3,1,1,2,3,1,1,1,2,1,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_about_per_week2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "100%",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','1.5','2','2.5','3','4','5','6','7','7.5','8','10','10.2','11','12','12.5','13.5','14','15','16','16.5','16.7','16.9','17.5','18','19','20','20.5','21','21.5','22.5','23','24','25','26','27','28','29.75','30','31.5','32','32.5','33.3','33.8','35','36','37.5','39.3','39.5','40','42','42.5','43','44','45','45.05','48','49','50','51','52','52.5','54','55','55.2','56','57','57.5','57.6','58','59.2','59.25','59.5','60','63','63.1','64','64.6','65','67.6','69','70','70.3','71.4','71.6','72','75','77','79','79.1','79.2','80','81.5','82','84','84.5','85','86','87.5','88.725','89.25','90','91','93.5','94','95.8','96','97','98','98.75','99','100','101.4','101.8','105','106.25','107','108','109','110','112','113.3','114','115','116.9','118.3','118.5','119','120','124','125','126','126.75','127','130','133','133.7','135','140','144','147','148.75','150','150.15','154','156','160','162','168','169','175','177.75','178.5','180','190','193','200','204','210','234','235','240','250','252','259','260','350','400','406','543','770','800'],
                    },
                    title: {
                    text: "How about per week?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [90,5,1,2,2,2,3,3,4,6,1,9,6,1,1,8,2,2,2,5,4,1,1,2,1,9,2,23,1,1,1,1,1,5,9,2,1,4,2,25,1,4,1,1,1,29,3,2,2,2,33,13,1,1,1,8,1,2,6,36,2,2,1,1,5,1,11,1,1,1,1,1,2,11,31,4,1,2,1,5,1,1,35,1,2,1,2,11,3,6,2,1,21,1,1,16,3,3,1,1,1,2,13,1,2,1,1,1,1,2,1,1,25,1,1,10,1,2,1,1,4,3,1,2,3,3,8,2,2,8,1,1,3,1,1,2,2,1,1,12,1,1,2,8,1,2,1,2,1,1,3,5,1,1,1,2,1,3,1,3,1,1,1,2,1,1,1,1,1,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_money__in_USD__do_you_spend_on_alcohol_per_week2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','5','6','9','10','12','15','16','20','21','25','26','27','28','30','32','35','36','38','40','42','43','45','49','50','52','60','62','65','68','70','72','75','80','82','84','85','90','100','105','111','112','115','119','120','125','126','130','137','140','144','150','160','163','165','175','176','180','182','190','200','210','220','240','250','277','280','300','350','420','500','666'],
                    },
                    title: {
                    text: "How much money (in USD) do you spend on alcohol per week?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [86,1,5,2,1,9,2,10,1,27,2,12,2,1,1,41,1,7,1,1,42,1,1,11,1,68,1,41,1,1,1,38,1,19,45,1,5,1,13,84,3,1,1,2,1,15,6,1,1,1,13,1,31,1,1,1,2,1,3,1,1,21,2,1,2,6,1,2,7,1,1,3,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            On_average__how_many_days_per_week_do_you_drink2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5','6','7'],
                    },
                    title: {
                    text: "On average, how many days per week do you drink?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [98,22,34,60,60,69,91,363]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Is_drinking_at_your_work_socially_acceptable2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Is drinking at your work socially acceptable?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [156,497,138]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_drink_at_work2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','None','A few days a year','A few days a month','A few days a week','Almost Every Day','Every Day'],
                    },
                    title: {
                    text: "How often do you drink at work?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [150,345,83,69,53,50,39]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_drink_before_work2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','None','A few days a year','A few days a month','A few days a week','Almost Every Day','Every Day'],
                    },
                    title: {
                    text: "How often do you drink before work?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [130,392,83,54,43,43,38]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_called_out_of_work_due_to_alcohol_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','0','1-3','4-6','7-10','11+'],
                    },
                    title: {
                    text: "How many times have you called out of work due to alcohol in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [122,273,200,97,47,42]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_blackout2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','Never','Rarely','On occasion','Most times','Always'],
                    },
                    title: {
                    text: "How often do you blackout?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [42,64,193,277,181,39]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_does_a_blackout_result_in_regret2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','Never','Not often','Occasionally','Very often','Every time'],
                    },
                    title: {
                    text: "How often does a blackout result in regret?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [82,42,120,140,185,220]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_have_a_drinking_buddy_that_can_keep_up_with_your_drinking2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Do you have a drinking buddy that can keep up with your drinking?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [52,406,294]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_your_friends_know_about_your_drinking_habits2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Do your friends know about your drinking habits?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [36,232,495]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Does_your_family_know_about_your_drinking_habits2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Does your family know about your drinking habits?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [26,325,415]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Did_you_get_a_DUI_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A (Didn’t drink didn’t drive etc)','No','Yes'],
                    },
                    title: {
                    text: "Did you get a DUI in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [161,595,20]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_DUIs_have_you_had_total__Numbers_only__please_2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5'],
                    },
                    title: {
                    text: "How many DUIs have you had total?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [643,106,36,9,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            What_type_of_alcohol_do_you_currently_prefer2020: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Beer','Bourbon','Brandy','Cider','Gin','Malt liquor','Rum','Tequila','Vodka','Whiskey','Wine'],
                    },
                    title: {
                    text: "What type of alcohol do you currently prefer?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [221,38,1,17,22,12,24,11,221,65,123]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            }
        },
        medical: {
            In_the_last_year__did_you_use_an_atypical_source_of_alcohol_such_as_mouthwash__vanilla_extract__cooking_wine__etc__to_fight_off_withdrawals2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "In the last year, did you use an atypical source of alcohol such as mouthwash, vanilla extract, cooking wine, etc. to fight off withdrawals?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [33,630,134]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Is_alcohol_your_first_choice_of_drug2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Is alcohol your first choice of drug?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [13,148,636]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_go_to_the_bar2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['I don’t','A few times a year','A few times a month','A few times a week','Every day'],
                    },
                    title: {
                    text: "How often do you go to the bar?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [323,233,139,85,17]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_believe_alcohol_has_made_you_more_reclusive2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No I don’t believe alcohol has affected this','Yes I’m less reclusive due to alcohol','Yes I’m more reclusive due to alcohol'],
                    },
                    title: {
                    text: "Do you believe alcohol has made you more reclusive?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [252,78,466]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_puke_from_drinking__or_associated_with_it_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','Never','A few times a year','A few times a month','A few times a week','Every day'],
                    },
                    title: {
                    text: "How often do you puke from drinking? (or associated with it)"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [51,167,319,163,80,17]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_take_anything_to_help_liver_function__Milk_thistle__B_vitamins__etc_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Do you take anything to help liver function? (Milk thistle, B vitamins, etc)"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [244,395,326]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_tried_to_stop_drinking_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes','Yes it was a forced decision (family court etc)'],
                    },
                    title: {
                    text: "Have you tried to stop drinking in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [30,221,418,53]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_tried_to_stop_drinking_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5','6','7','8','9','10+','Other'],
                    },
                    title: {
                    text: "How many times have you tried to stop drinking in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [222,91,127,80,48,40,11,4,6,2,81,10]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Of_the_time_s_you_ve_tried_to_stop__which_programs_methods_did_you_use2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: ['Cold Turkey','Tapering','None','CA Subreddit','Dryalcoholics Subreddit','Stopdrinking Subreddit','AA 12 Step','Inpatient Outpatient Rehab','Moderation Management','Naltrexone Vivitrol','SMART','Group Therapy','Recovery Support Services','Hotline s','Antabuse','Campral','SLE (Sober Living Environment)','Court Ordered Rehabilitation','Individual therapy','Benzos','Therapy',' r cutdowndrinking power will','[book] Alcohol Explained','Addiction specialized personal therapist','Addiction Therapist','App','Baclofen','Benzodiazepines','Detox psych ward','ER Detox','Gabapentin','HAMS harm reduction','Hospital Detox','I had breaks to convince myself I’m not an alcoholic to continue drinking','I tell myself I won’t pick a bottle up at least a few times a week almost every week. I almost always fail.','Illness','Klonopin','kratom','Kratom ketamine','Mental health facility - Self Admitted','Naltrexone','No drinking for a month or two. Dry January basically.','Peer support','Private psychotherapy','Quit lit','self will and the knowledge that I am better than everyone else','Substance abuse therapy','This Naked Mind','TMS for depression anxiety. Also Im not sure which of these is individual therapy but that one.','Tracking use individual therapy','Weed','Xanax'],
                    },
                    title: {
                    text: "Of the time s you've tried to stop, which programs methods did you use?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [256,249,221,171,160,149,127,99,92,83,49,46,26,21,20,16,14,12,4,2,2,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_started_inpatient_treatment_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5'],
                    },
                    title: {
                    text: "How many times have you started inpatient treatment in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [655,39,7,2,1,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_completed_inpatient_treatment_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5','8','9','10+'],
                    },
                    title: {
                    text: "How many times have you completed inpatient treatment in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [662,30,4,1,1,2,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_been_to_detox_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5','7','10+'],
                    },
                    title: {
                    text: "How many times have you been to detox in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [628,43,9,9,5,3,2,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_been_to_inpatient_treatment_over_the_course_of_your_life____skip_if_you_haven_t_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5','6','7','8','10+'],
                    },
                    title: {
                    text: "How many times have you been to inpatient treatment over the course of your life?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [404,89,29,20,8,5,6,4,2,7]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_been_to_detox_over_the_course_of_your_life__Skip_if_you_haven_t_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5','6','7','8','9','10','12','13','14','17','20','30','60','100'],
                    },
                    title: {
                    text: "How many times have you been to detox over the course of your life?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [123,59,25,14,16,8,5,4,3,1,5,1,1,1,1,2,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            If_you_have_tried_to_stop_in_the_last_year__how_long_was_the_longest_period_it_was_effective2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','Up to a day','A day to a week','A week to a month','A month to 3 months','3 months to 6 months','6 months to a year'],
                    },
                    title: {
                    text: "If you have tried to stop in the last year, how long was the longest period it was effective?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [145,31,144,157,85,48,32]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_contracted_a_lasting_illness_as_a_result_of_your_drinking2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Have you contracted a lasting illness as a result of your drinking?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [243,620,102]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            When_was_the_last_time_you_went_to_a_scheduled_medical_appointment__alcohol_related_or_otherwise_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['Less than a month','A month to three months','Three months to six months','Six months to a year','A year or longer'],
                    },
                    title: {
                    text: "When was the last time you went to a scheduled medical appointment (alcohol related or otherwise)"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [131,141,84,100,265]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_alcohol_related_hospitalizations_have_you_had_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['0','1','2','3','4','5','6','8','10+'],
                    },
                    title: {
                    text: "How many alcohol-related hospitalizations have you had in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [593,76,25,9,7,4,3,1,4]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_your_family_friends_staged_an_intervention_for_you_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Have your family/friends staged an intervention for you in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [243,648,74]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_served_in_the_armed_forces_in_your_respective_country2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Have you served in the armed forces in your respective country?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [243,680,42]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Has_it_contributed_to_your_drinking2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','Yes'],
                    },
                    title: {
                    text: "Has it contributed to your drinking?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [33,25]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_has_depression_affected_you2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5'],
                    },
                    title: {
                    text: "How much has depression affected you?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [26,77,145,215,258]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_do_you_believe_drinking_has_affected_the_depress__1___No_negative_effects__2___Slight_negative_effects__3___Moderate_negative_effects__4___Heavy_negative_effects__5___Severely_negative_effects_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5'],
                    },
                    title: {
                    text: "How much do you believe drinking has affected the depression?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severely negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [64,82,184,188,203]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_has_general_anxiety_affected_you__1___No_negative_effects__2___Slight_negative_effects__3___Moderate_negative_effects__4___Heavy_negative_effects__5___Severely_negative_effects_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5'],
                    },
                    title: {
                    text: "How much has general anxiety affected you?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severely negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [34,67,123,216,281]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_do_you_believe_drinking_has_affected_the_general_anxiety__1___No_negative_effects__2___Slight_negative_effects__3___Moderate_negative_effects__4___Heavy_negative_effects__5___Severely_negative_effects_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5'],
                    },
                    title: {
                    text: "How much do you believe drinking has affected the general anxiety?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severely negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [86,91,132,179,233]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_has_social_anxiety_affected_you__1___No_negative_effects__2___Slight_negative_effects__3___Moderate_negative_effects__4___Heavy_negative_effects__5___Severely_negative_effects_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5'],
                    },
                    title: {
                    text: "How much has social anxiety affected you?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severely negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [85,112,149,144,231]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_do_you_believe_drinking_has_affected_the_social_anxiety__1___No_negative_effects__2___Slight_negative_effects__3___Moderate_negative_effects__4___Heavy_negative_effects__5___Severely_negative_effects_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5'],
                    },
                    title: {
                    text: "How much do you believe drinking has affected the social anxiety?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severely negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [199,122,137,98,165]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Does_being_a_CA_affect_your_personal_relationships__1___No_negative_effects__2___Slight_negative_effects__3___Moderate_negative_effects__4___Heavy_negative_effects__5___Severely_negative_effects_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5'],
                    },
                    title: {
                    text: "Does being a CA affect your personal relationships?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severely negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [115,106,149,140,211]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Does_being_a_CA_affect_your_romantic_relationships__1___No_negative_effects__2___Slight_negative_effects__3___Moderate_negative_effects__4___Heavy_negative_effects__5___Severely_negative_effects_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5'],
                    },
                    title: {
                    text: "Does being a CA affect your romantic relationships?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severely negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [116,85,129,141,250]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Does_being_a_CA_affect_your_social_life__1___No_negative_effects__2___Slight_negative_effects__3___Moderate_negative_effects__4___Heavy_negative_effects__5___Severely_negative_effects_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5'],
                    },
                    title: {
                    text: "Does being a CA affect your social life?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severely negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [132,111,144,132,202]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Does_your_family_have_a_history_of_alcoholism2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Does your family have a history of alcoholism?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [244,157,564]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_THINK_you_might_have_any_of_these_disorders2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '12',
                    height: "100%",
                    chart: {
                    type: 'column',
                    styledMode: false
                    },
                    dataLabels: {
                    enabled: true
                    },
                    xAxis: {
                    categories: ['Depressive Disorder','General Anxiety','Social Anxiety Disorder','ADD/ADHD','None','PTSD','Panic Disorder','Bipolar Disorder','OCD','Borderline Personality Disorder','Chronic Pain Disorder','Bulimia Nervosa','Anorexia Nervosa','Agoraphobia','Depersonalization Disorder','Antisocial Disorder','Psychosis','Other']
                    },
                    title: {
                    text: "Diagnosis"
                    },
                    colorAxis: '',
                    series: [
                    {
                        name: 'Do you THINK you might have any of these disorders?',
                        data: [483,422,271,204,63,170,115,110,101,85,61,51,47,53,50,41,26,130]
                    },
                    {
                        name: 'Have you been diagnosed by a doctor for any of the following disorders?',
                        data: [380,307,107,109,228,81,61,65,50,32,35,24,26,16,14,3,14,43]
                    }
                    ],
                    legend: {
                    enabled: true
                    }
                }
            },
            Do_you_receive_medication_for_any_disorders_listed_above2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Do you receive medication for any disorders listed above?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [378,301,286]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Has_drinking_worsened_the_symptoms_of_any_of_the_disorders_above2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','Not sure','Yes'],
                    },
                    title: {
                    text: "Has drinking worsened the symptoms of any of the disorders above?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [57,175,365]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_ever_self_harmed__besides_drugs_alcohol_2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Have you ever self harmed (besides drugs/alcohol)?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [244,354,367]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "In the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [480,325,160]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_receive_regular_psychiatric_psychological_care_or_therapy2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Do you receive regular psychiatric/psychological care or therapy?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [244,535,186]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_hours_of_sleep_do_you_usually_get_on_average2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','16','17','24'],
                    },
                    title: {
                    text: "How many hours of sleep do you usually get on average?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,6,6,47,119,203,145,98,51,20,9,11,1,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_had_a_night_terror_in_the_last_year2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['N/A','No','Yes'],
                    },
                    title: {
                    text: "Have you had a night terror in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [246,342,377]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_regularly_piss_the_bed_from_drinking2020: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: ['No','No irregularly','Yes'],
                    },
                    title: {
                    text: "Do you regularly piss the bed from drinking?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [549,117,27]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
                        Do_you_think_alcoholism_has_affected_your_memory__not_counting_while_blacked_out_2020: { 
            chartOptions: {
                main: 'Drug Use',
                size: '6',
                height: "auto",
                chart: {
                type: 'bar'
                },
                xAxis: {
                    categories: ['N/A','No','Yes'],
                },
                title: {
                    text: "Do you think alcoholism has affected your memory (not counting while blacked out)?"
                },
                series: [
                    {
                    name: 'Count',
                    data: [280,129,556]
                    }
                ],
                legend: {
                    enabled: false
                }
                }
            }
        },
        drug_Use: {
            Current_Tobacco_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Tobacco use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [298,88,76,74,153]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Tobacco_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Tobacco use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [114,63,71,119,320]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Nicotine_Vaping_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Nicotine Vaping use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [464,38,24,38,123]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Nicotine_Vaping_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Nicotine Vaping use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [385,69,44,47,141]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Marijuana_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Marijuana use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [279,159,93,100,58]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Marijuana_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Marijuana use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [91,140,142,141,172]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Hash_or_other_marijuana_concentrates_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Hash or other marijuana concentrates use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [496,77,61,33,22]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Hash_or_other_marijuana_concentrates_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Hash or other marijuana concentrates use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [350,86,99,76,75]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Cocaine_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Cocaine use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [515,106,52,12,3]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Cocaine_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Cocaine use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [318,155,115,53,45]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Crack_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','5'],
                    },
                    title: {
                        text: "Current Crack use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [670,11,6,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Crack_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Crack use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [602,50,22,6,6]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Light_sleeping_medication__antihistamines__diphenhydramine__etc_use2020__Melatonin__Valarian_root__etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Light sleeping medication (antihistamines [diphenhydramine, etc use, Melatonin, Valarian root, etc) use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [310,164,82,101,31]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Light_sleeping_medication__antihistamines__diphenhydramine__etc_use2020__Melatonin__Valarian_root__etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Light sleeping medication (antihistamines [diphenhydramine, etc use, Melatonin, Valarian root, etc) use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [283,169,89,111,34]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Other_sleep_medications__Hypnotic_benzos__temazepan__nitrazepan__etc___Z_drugs__Lunesta__ambien__etc___etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Other sleep medications: Hypnotic benzos (temazepan, nitrazepan, etc), Z-drugs (Lunesta, ambien, etc), etc. use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [558,53,26,38,13]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Other_sleep_medications__Hypnotic_benzos__temazepan__nitrazepan__etc___Z_drugs__Lunesta__ambien__etc___etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Other sleep medications: Hypnotic benzos (temazepan, nitrazepan, etc), Z-drugs (Lunesta, ambien, etc), etc. use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [488,94,40,35,29]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Prescription_amphetamines_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Prescription amphetamines use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [572,46,34,24,12]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Prescription_amphetamines_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Prescription amphetamines use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [441,73,88,44,40]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Methamphetamine_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Methamphetamine use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [661,16,6,2,3]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Methamphetamine_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Methamphetamine use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [580,49,30,10,17]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_MDMA_estacy_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current MDMA/estacy use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [572,79,34,2,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_MDMA_estacy_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past MDMA/estacy use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [427,131,94,20,14]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Mushrooms_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Mushrooms use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [515,117,49,6,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Mushrooms_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Mushrooms use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [375,175,108,24,4]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_LSD_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current LSD use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [580,68,34,5,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_LSD_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past LSD use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [436,125,86,30,9]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Mescaline_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3'],
                    },
                    title: {
                        text: "Current Mescaline use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [681,6,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Mescaline_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Mescaline use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [644,27,12,1,2]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Heroin_Opium_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Heroin/Opium use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [668,11,4,2,3]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Heroin_Opium_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Heroin/Opium use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [594,45,8,8,31]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Kratom_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Kratom use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [594,35,18,23,18]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Kratom_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Kratom use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [558,47,31,22,28]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Kava_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Kava use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [648,33,5,1,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Kava_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Kava use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [612,49,17,6,2]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Gabanergics__Kratom__Phenibut__etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Gabanergics (Kratom, Phenibut, etc) use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [604,30,19,19,16]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Gabanergics__Kratom__Phenibut__etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Gabanergics (Kratom, Phenibut, etc) use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [581,38,25,21,21]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Prescription_opiates_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Prescription opiates use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [440,106,60,38,42]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Prescription_opiates_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Prescription opiates use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [592,64,16,7,9]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Research_chemicals__2c_i__methylone__etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Research chemicals (2c-i, methylone, etc) use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [660,22,3,1,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Research_chemicals__2c_i__methylone__etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Research chemicals (2c-i, methylone, etc) use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [596,50,24,6,10]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Barbituates_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Barbituates use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [676,8,1,1,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Barbituates_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Barbituates use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [658,18,5,2,3]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Anxiolytic_Benzodiazapines__Valium__Klonopin__Xanax__Librium__Ativan__etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Anxiolytic Benzodiazapines (Valium, Klonopin, Xanax, Librium, Ativan, etc) use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [513,86,39,31,19]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Anxiolytic_Benzodiazapines__Valium__Klonopin__Xanax__Librium__Ativan__etc__use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Anxiolytic Benzodiazapines (Valium, Klonopin, Xanax, Librium, Ativan, etc) use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [368,122,88,63,45]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_GHB_GBL_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current GHB/GBL use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [676,8,2,1,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_GHB_GBL_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past GHB/GBL use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [660,16,5,1,4]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Amyl_Nitrate_poppers_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Amyl Nitrate/poppers use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [643,28,10,4,3]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Amyl_Nitrate_poppers_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Amyl Nitrate/poppers use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [609,49,20,6,2]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Ketamine_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Ketamine use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [640,32,10,4,2]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Ketamine_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Ketamine use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [578,68,27,9,4]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_DXM_cough_syrup_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current DXM/cough syrup use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [630,43,10,4,1]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_DXM_cough_syrup_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past DXM/cough syrup use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [528,89,36,21,12]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_Nitrous_oxide_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current Nitrous oxide use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [624,43,16,2,3]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_Nitrous_oxide_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past Nitrous oxide use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [552,66,46,11,10]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Current_DMT_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Current DMT use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [657,22,5,2,2]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            },
            Past_DMT_use2020: { 
                chartOptions: {
                    main: 'Drug Use',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                        categories: ['1','2','3','4','5'],
                    },
                    title: {
                        text: "Past DMT use"
                    },
                    series: [
                        {
                        name: 'Count',
                        data: [617,46,18,4,2]
                        }
                    ],
                    legend: {
                        enabled: false
                    }
                    }
            }
        },
    }]
}
