export let results2022 = {
    categories: ["demographics","fun","drinking","medical","drug_Use"],
    charts: [{
       demographics: {
            Subreddit_activity: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`Active commenter`,`Active poster`,`Active poster & commenter`,`Lurker`],
                    },
                    title: {
                    text: "Subreddit activity?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [29,2,45,34]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_old_are_you__years_: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`21`,`22`,`23`,`24`,`25`,`26`,`27`,`28`,`29`,`30`,`31`,`32`,`34`,`35`,`36`,`37`,`38`,`39`,`40`,`41`,`42`,`43`,`44`,`45`,`46`,`47`,`48`,`50`,`51`,`52`,`53`,`59`,`72`],
                    },
                    title: {
                    text: "How old are you? (years)"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,2,1,5,7,3,5,5,1,6,2,8,6,4,3,5,5,6,4,5,1,4,2,1,6,1,1,2,2,1,2,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Height: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`4' 10" (147 cm)`,`5' (152 cm)`,`5' 1" (155 cm)`,`5' 2" (157 cm)`,`5' 3" (160 cm)`,`5' 4" (162 cm)`,`5' 5" (165 cm)`,`5' 6" (168 cm)`,`5' 7" (170 cm)`,`5' 8" (173 cm)`,`5' 9" (175 cm)`,`5' 10" (178 cm)`,`5' 11" (180 cm)`,`6' (183 cm)`,`6' 1" (185 cm)`,`6' 2" (188 cm)`,`6' 3" (190 cm)`,`6' 4" (193 cm)`,`6' 6" (198 cm)`,`7' or higher (213 cm)`],
                    },
                    title: {
                    text: "Height?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,1,2,3,9,7,9,10,6,5,12,6,7,11,5,10,2,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Gender_identity: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`Female`,`Female (Trans)`,`Male`,`Male (Trans)`,`Non-binary`],
                    },
                    title: {
                    text: "Gender identity"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [47,2,57,1,3]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Sexual_Orientation: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`Asexual`,`Bi Curious`,`Bisexual`,`Ethanol Enthusiast`,`Gay`,`Out of commission`,`Pansexual`,`Straight`,`Undecided`],
                    },
                    title: {
                    text: "Sexual Orientation"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [3,1,24,1,3,1,3,71,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__have_you_been_affected_by_houselessness: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Yes`],
                    },
                    title: {
                    text: "In the last year, have you been affected by houselessness?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [100,10]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Which_country_do_you_live_in: {
                            chartOptions: {
                                type: "map",
                                mapChart: "mapChart",
                                class: "map",
                                size: "6",
                                chart: {
                                map: "myMapName"
                                },
                                title: {
                                text: "Which country do you live in?"
                                },
                                subtitle: {
                                text:
                                    ''
                                },
                                mapNavigation: {
                                enabled: true,
                                buttonOptions: {
                                    alignTo: "spacingBox"
                                }
                                },
                                colorAxis: {
                                min: 0
                                },
                                series: [
                                {
                                    name: "Count",
                                    states: {
                                    hover: {
                                        color: "#BADA55"
                                    }
                                    },
                                    dataLabels: {
                                    enabled: true,
                                    format: "{point.name}"
                                    },
                                    allAreas: true,
                                    data: [[`au`,5],[`be`,1],[`ca`,16],[`dk`,1],[`fi`,1],[`de`,2],[`it`,1],[`no`,3],[`es`,1],[`ch`,1],[`tr`,1],[`gb`,5],[`us`,70]]
                                }
                                ]
                            }
                            },If_you_re_in_the_USA_or_Canada__which_state_or_province_are_you_located_in: { 
                                chartOptions: {
                                type: "map",
                                mapChart: "mapChart",
                                class: "map",
                                size: "6",
                                chart: {
                                map: "NaCan"
                                },
                                title: {
                                text: `If you're in the USA or Canada, which state or province are you located in?`
                                },
                                subtitle: {
                                text:
                                    ""
                                },
                                mapNavigation: {
                                enabled: true,
                                buttonOptions: {
                                    alignTo: "spacingBox"
                                }
                                },
                                colorAxis: {
                                min: 0
                                },
                                series: [
                                {
                                    name: "Count",
                                    states: {
                                    hover: {
                                        color: "#BADA55"
                                    }
                                    },
                                    dataLabels: {
                                    enabled: true,
                                    format: "{point.name}"
                                    },
                                    allAreas: true,
                                    data: [[`us-al`,1],[`ca-ab`,1],[`us-az`,2],[`ca-bc`,2],[`us-ca`,10],[`us-co`,2],[`us-ct`,2],[`us-fl`,2],[`us-ga`,1],[`us-id`,1],[`us-il`,3],[`us-ia`,1],[`us-ky`,1],[`us-la`,3],[`ca-mb`,1],[`us-mi`,6],[`us-mn`,1],[`us-mo`,2],[`us-ne`,1],[`us-nj`,3],[`us-ny`,1],[`ca-nl`,1],[`us-nc`,2],[`us-oh`,2],[`ca-on`,9],[`us-or`,4],[`us-pa`,2],[`ca-sk`,1],[`us-sc`,1],[`us-sd`,1],[`us-tn`,1],[`us-tx`,3],[`us-ut`,3],[`us-va`,1],[`us-dc`,1],[`us-wv`,1]]
                                }
                                ]
                            }
                            },Education: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`Did not graduate high school`,`High school or equivalent`,`Some college or university`,`College or university graduate`,`Some graduate school or continuing education`,`Completed graduate school or other continuing education`,`Trade school graduate`],
                    },
                    title: {
                    text: "Education"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [4,13,29,36,8,15,5]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Religion: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '9',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`...I'm just a witch.`,`Agnostic`,`Atheist`,`Atheistic Satanist`,`Born Muslim. Not now.`,`Buddhism`,`Catholic`,`Christened but fuck that shit`,`Christianity`,`Hinduism`,`Neopaganism`,`None`,`Not sure/Undecided`,`Satanist`,`Spiritual`,`Unitarian Universalist `,`Wicca`],
                    },
                    title: {
                    text: "Religion"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,28,24,1,1,1,1,1,11,1,1,17,9,1,9,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__how_many_times_have_you_been_arrested: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`],
                    },
                    title: {
                    text: "In the last year, how many times have you been arrested?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [101,8,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Did_the_last_arrest_involve_alcohol: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`No, but alcohol was involved`,`Yes`],
                    },
                    title: {
                    text: "Did the last arrest involve alcohol?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [0,2,7]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__how_many_times_have_you_been_to_jail: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`],
                    },
                    title: {
                    text: "In the last year, how many times have you been to jail?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [105,4,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_relationship_status: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`Single`,`Committed Relationship`,`Engaged`,`Married`,`Separated`,`Divorced`],
                    },
                    title: {
                    text: "Current relationship status"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [44,33,3,21,3,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            If_you_re_currently_married_or_in_a_committed_relationship__is_your_partner_s_a_CA: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`They don't drink`,`They drink occasionally`,`They drink regularly`,`Alcoholic`,`CA`],
                    },
                    title: {
                    text: "If you're currently married or in a committed relationship, is your partner/s a CA?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [6,28,12,9,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Your_upbringing_was_or_your_parents_were____: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`Adopted`,`Fostered`,`Homeless`,`It's complicated`,`Married`,`Never Married`,`Raised by grandparents/other family`,`Raised by single father`,`Raised by single mother`,`Separated / divorced`],
                    },
                    title: {
                    text: "Your upbringing was or your parents were...."
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,1,1,6,62,4,1,2,5,26]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_children_do_you_have: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`,`3`,`4`,`10`],
                    },
                    title: {
                    text: "How many children do you have?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [83,12,9,4,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Are_you_currently_enrolled_as_a_student: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Yes`,`Yes, and I work`],
                    },
                    title: {
                    text: "Are you currently enrolled as a student?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [98,6,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            What_industry_do_you_currently_work_in: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "auto",
                    chart: {
                        type: 'column',
                        scrollablePlotArea: {
                            minWidth: 1000,
                            scrollPositionX: 0
                        }
                    },
                    xAxis: {
                    categories: [`Accounting`,`Admin - clerical`,`Art/creative`,`Automotive`,`aviation`,`Biotechnology `,`Carer for retards`,`Construction`,`Disabled`,`Distribution - shipping`,`Education`,`Engineering`,`Entertainment`,`Executive`,`General labor`,`Government`,`Health Care`,`Hospitality`,`Information technology`,`Legal`,`Librarian`,`Management`,`Manufacturing`,`Media/journalism/newspaper`,`Millitary`,`None / Unemployed`,`Online sex work. `,`Outdoor power equipment `,`Pharmaceutical`,`QA/quality control`,`Restaurant/food service`,`Retail`,`Retired`,`Sales`,`Seeking disability `,`Skilled labor/trades`,`Social housing `,`Travel`,`Truck Driver `],
                    },
                    title: {
                    text: "What industry do you currently work in?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,1,1,1,1,1,1,1,1,1,5,3,1,2,1,4,7,3,10,5,1,1,2,2,1,23,1,1,2,2,6,5,1,2,1,4,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            What_s_the_longest_you_ve_held_a_job: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '9',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`Month/s`,`1 Year`,`2 Years`,`3 Years`,`4 Years`,`5 Years`,`6 Years`,`7 Years`,`8 Years`,`9 Years`,`10 Years`,`11-15 Years`,`21-25 Years`,`30+ Years`],
                    },
                    title: {
                    text: "What's the longest you've held a job?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [3,4,8,6,21,10,11,7,7,4,5,6,12,2,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__have_you_gained_or_lost_a_job: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '3',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`N/A`,`Gained a job`,`Lost a job`,`Both`,`Neither (stayed unemployed)`,`Neither (stayed employed)`],
                    },
                    title: {
                    text: "In the last year, have you gained or lost a job?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [6,14,8,25,11,46]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__how_much_money_did_you_earn__in_USD_: { 
                chartOptions: {
                    main: 'Demographics',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`$0-$10,000`,`$10,001-$20,000`,`$20,001-$30,000`,`$30,001-$40,000`,`$40,001-$50,000`,`$50,001-$60,000`,`$60,001-$70,000`,`$70,001-$80,000`,`$80,001-$90,000`,`$90,001-$100,000`,`$100,000+`],
                    },
                    title: {
                    text: "In the last year, how much money did you earn? (in USD)"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [7,14,11,14,5,9,5,8,3,3,13]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
        
        
       }, //demographics
       fun: {
            How_often_do_you_shower_bathe: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`Seldom`,`When there's a reason to/"as needed"`,`Only days I work`,`Once every few days`,`Every other day`,`Once a day`,`Multiple times a day`],
                    },
                    title: {
                    text: " How often do you shower/bathe?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,7,6,26,20,37,5]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_brush_your_teeth: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`None`,`Seldom`,`Almost every day`,`1 time per day`,`2 times per day`,`3 times per day`],
                    },
                    title: {
                    text: " How often do you brush your teeth?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,11,21,31,35,3]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_floss_your_teeth: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`None`,`Seldom`,`A few times a week`,`Almost every day`,`Every day`,`2 times per day`],
                    },
                    title: {
                    text: " How often do you floss your teeth?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [26,36,24,11,6,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_drink_coffee_regularly: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`I don't drink coffee`,`Seldom`,`A few times a month`,`A few times a week`,`Almost every day`,`Every day`],
                    },
                    title: {
                    text: " Do you drink coffee regularly?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [16,18,6,8,20,36]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_have_any_pets: { 
                chartOptions: {
                    main: 'Fun',
                    size: '12',
                    height: "auto",
                    chart: {
                        type: 'column',
                        scrollablePlotArea: {
                            minWidth: 500,
                            scrollPositionX: 0
                        },
                    },
                    xAxis: {
                    categories: [`Dog/s`,`Cat/s`,`None`,`Reptile/s`,`Fish`,`Guinea Pig/s`,`Chicken/s`,`Snake/s`,`Bird/s`,`Rabbit/s`,`Honeybees`,`Ducks`,`Alpacas`],
                    },
                    title: {
                    text: " Do you have any pets?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [42,38,37,5,3,3,2,2,1,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__which_sports_have_you_followed: { 
                chartOptions: {
                    main: 'Fun',
                    size: '12',
                    height: "auto",
                    chart: {
                        type: 'column',
                        scrollablePlotArea: {
                            minWidth: 800,
                            scrollPositionX: 0
                        },
                    },
                    xAxis: {
                    categories: [`None`,`Football`,`Soccer (USA)/Football (UK)`,`Basketball`,`Hockey`,`Baseball`,`Formula 1`,`UFC`,`Boxing`,`Chess`,`Auto Racing`,`Cricket`,`MMA`,`E-sports`,`Skateboarding`,`Surfing`,`Cycling`,`Golf`,`Rugby`,`Weightlifting`,`Climbing`,`Tennis`,`Disc Golf`,`Lacrosse`,`Running`,`Skiing`,`Snooker`,`Snowboarding`,`Sumo`,`Swimming`],
                    },
                    title: {
                    text: "In the last year, which sports have you followed?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [43,31,18,13,13,10,7,7,6,6,5,5,5,4,4,4,3,3,3,3,2,2,1,1,1,1,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_consider_yourself_a_violent_person: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Yes`],
                    },
                    title: {
                    text: " Do you consider yourself a violent person?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [97,4]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__have_you_gotten_into_a_physical_fight_: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Yes`],
                    },
                    title: {
                    text: "In the last year, have you gotten into a physical fight? "
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [88,13]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_own_a_gun_multiple_guns: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Yes`],
                    },
                    title: {
                    text: " Do you own a gun/multiple guns?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [82,20]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__have_you_shot_it_them: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Yes`],
                    },
                    title: {
                    text: "In the last year, have you shot it/them?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [8,12]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_languages_do_you_speak_fluently: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`],
                    },
                    title: {
                    text: " How many languages do you speak fluently?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [83,14,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Here_we_are_again____poop__What_s_your_normal_consistency: { 
                chartOptions: {
                    main: 'Fun',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`Type 1`,`Type 2`,`Type 3`,`Type 4`,`Type 5`,`Type 6`,`Type 7`],
                    },
                    title: {
                    text: " Here we are again... poop. What's your normal consistency? (Type 7 = pure liquid, type 1 = hard pellets)"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [5,3,10,36,15,25,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
        
        
       }, //fun
       drinking: {
            Do_you_consider_yourself_an_alcoholic: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Possibly`,`Yes, high functioning`,`Yes, between crippling and high functioning`,`Yes, crippling alcoholism`,`Yes, in recovery`],
                    },
                    title: {
                    text: "Do you consider yourself an alcoholic?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,6,14,47,14,15]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            For_those_in_recovery__how_long_have_you_been_recovering: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`1 week or less`,`1 month or less`,`3 months or less`,`6 months or less`,`1 year or less`,`2 years or less`,`4 years or less`],
                    },
                    title: {
                    text: "For those in recovery, how long have you been recovering?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,1,1,3,6,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_consider_yourself_a_binge_drinker_or_maintenance_drinker: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`Maintenance Drinker`,`Binge Drinker`,`Both`,`Neither`,`Boredom drinker`,`Unsure`],
                    },
                    title: {
                    text: "Do you consider yourself a binge drinker or maintenance drinker?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [14,31,47,2,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            On_average__how_many_days_per_week_do_you_drink: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`,`3`,`4`,`5`,`6`,`7`],
                    },
                    title: {
                    text: "On average, how many days per week do you drink?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [11,2,5,6,4,13,6,46]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            At_what_age_did_you_have_your_first_drink: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`1`,`4`,`5`,`6`,`8`,`10`,`11`,`12`,`13`,`14`,`15`,`16`,`17`,`18`,`19`,`20`,`21`],
                    },
                    title: {
                    text: "At what age did you have your first drink?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,1,1,1,4,7,3,7,17,17,12,10,3,6,2,2,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            At_what_age_did_you_start_drinking_reulgarly: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`12`,`13`,`14`,`15`,`16`,`17`,`18`,`19`,`20`,`21`,`22`,`23`,`24`,`25`,`30`,`31`,`34`,`35`,`N/A`],
                    },
                    title: {
                    text: "At what age did you start drinking reulgarly?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,1,2,4,10,13,15,8,7,9,7,5,4,2,2,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            At_what_age_did_you_become_physically_dependent_on_alcohol: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`14`,`17`,`18`,`19`,`20`,`21`,`22`,`23`,`24`,`25`,`26`,`27`,`28`,`29`,`30`,`32`,`34`,`35`,`36`,`37`,`38`,`39`,`40`,`43`,`44`,`N/A`],
                    },
                    title: {
                    text: "At what age did you become physically dependent on alcohol?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,2,2,4,8,3,8,6,8,5,4,5,2,1,5,3,3,4,2,1,2,2,1,1,1,10]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            When_maintaining__approximately_how_many_drinks_do_you_have_per_day: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`0`,`3`,`4`,`5`,`6`,`7`,`8`,`9`,`10`,`11`,`12`,`13`,`15`,`17`,`20`,`25`,`30`,`37`],
                    },
                    title: {
                    text: "When maintaining, approximately how many drinks do you have per day?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [8,5,3,7,12,5,14,1,8,2,13,3,4,1,2,3,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            When_binging__approximately_how_many_drinks_do_you_have_per_day: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`4`,`5`,`6`,`7`,`8`,`10`,`11`,`12`,`13`,`14`,`15`,`16`,`17`,`18`,`20`,`21`,`22`,`24`,`25`,`26`,`30`,`32`,`33`,`35`,`40`,`50`],
                    },
                    title: {
                    text: "When binging, approximately how many drinks do you have per day?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,1,2,1,4,8,3,9,4,5,12,5,3,3,12,1,1,3,4,3,3,1,1,1,4,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_money__in_USD__do_you_spend_on_alcohol_per_week: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`0`,`10`,`16`,`20`,`25`,`30`,`35`,`40`,`50`,`55`,`56`,`60`,`63`,`65`,`70`,`75`,`76`,`80`,`90`,`95`,`100`,`120`,`125`,`130`,`150`,`169`,`175`,`200`,`250`,`300`,`400`],
                    },
                    title: {
                    text: "How much money (in USD) do you spend on alcohol per week?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [6,1,1,2,5,6,1,1,10,1,1,5,1,1,3,2,1,3,2,1,21,3,1,2,6,1,1,2,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Is_drinking_at_your_work_socially_acceptable: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: "Is drinking at your work socially acceptable?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [7,58,14]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_drink_at_work: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`A few days a year`,`A few days a month`,`A few days a week`,`Almost Every Day`,`Every Day`,`N/A`,`None`],
                    },
                    title: {
                    text: "How often do you drink at work?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [13,5,9,5,3,9,35]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_drink_before_work: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`A few days a year`,`A few days a month`,`A few days a week`,`Almost Every Day`,`Every Day`,`N/A`,`None`],
                    },
                    title: {
                    text: "How often do you drink before work?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [12,8,8,5,5,10,30]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__how_many_times_have_you_called_out_of_work_due_to_alcohol: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`0`,`1-3`,`4-6`,`7-10`,`11+`,`N/A`],
                    },
                    title: {
                    text: "In the last year, how many times have you called out of work due to alcohol?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [22,19,18,7,4,9]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__how_many_times_have_you_been_dismissed_from_work_due_to_alcohol: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`,`4`,`5`,`10+`,`N/A`],
                    },
                    title: {
                    text: "In the last year, how many times have you been dismissed from work due to alcohol?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [63,5,2,1,1,1,24]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_often_do_you_blackout: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`Never`,`Rarely`,`On occasion`,`Most times`,`Always`,`N/A`],
                    },
                    title: {
                    text: "How often do you blackout?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,12,46,32,4,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_have_a_drinking_buddy_that_can_keep_up_with_your_drinking: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: " Do you have a drinking buddy that can keep up with your drinking?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [5,58,32]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_your_friends_know_about_your_drinking_habits: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: " Do your friends know about your drinking habits?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,33,60]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Does_your_family_know_about_your_drinking_habits: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: " Does your family know about your drinking habits?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,44,45]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__have_you_tried_to_hide_your_drinking_from_friends_or_family: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`Friends`,`Family`,`Both`,`Neither`,`N/A`],
                    },
                    title: {
                    text: "In the last year, have you tried to hide your drinking from friends or family?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [0,18,61,14,3]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_DUIs_have_you_had_total: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`,`4`],
                    },
                    title: {
                    text: " How many DUIs have you had total?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [75,16,3,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Did_you_get_a_DUI_in_the_last_year: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A (Didn't drink, didn't drive, etc)`,`No`,`Yes`],
                    },
                    title: {
                    text: " Did you get a DUI in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [3,17,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Alcohol_preference: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '12',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`Beer`,`Bourbon`,`Brandy`,`Cider`,`Gin`,`Rum`,`Vodka`,`Whiskey`,`Wine`],
                    },
                    title: {
                    text: "Alcohol preference"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [24,4,1,1,1,3,41,10,12]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__did_you_use_an_atypical_source_of_alcohol_such_as_mouthwash__vanilla_extract__cooking_wine__etc__to_fight_off_withdrawals: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: " In the last year, did you use an atypical source of alcohol such as mouthwash, vanilla extract, cooking wine, etc. to fight off withdrawals?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,67,26]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__has_alcohol_been_your_first_choice_of_drug: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Yes`],
                    },
                    title: {
                    text: " In the last year, has alcohol been your first choice of drug?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [9,86]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__how_often_did_you_go_to_bars: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`I don't`,`A few times a year`,`A few times a month`,`A few times a week`,`Every day`],
                    },
                    title: {
                    text: " In the last year, how often did you go to bars?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [30,23,28,14,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__how_often_have_you_puked_from__or_associated_with__drinking: { 
                chartOptions: {
                    main: 'Drinking',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'column'
                    },
                    xAxis: {
                    categories: [`Never`,`A few times a year`,`A few times a month`,`A few times a week`,`Every day`,`N/A`],
                    },
                    title: {
                    text: " In the last year, how often have you puked from (or associated with) drinking?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [19,34,28,10,2,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
        
       }, //drinking
       medical: {
            In_the_last_year__what_symptoms_have_you_experienced_as_a_result_of_alcohol: { 
                chartOptions: {
                    main: 'Medical',
                    size: '12',
                    height: "auto",
                    chart: {
                        type: 'column',
                        scrollablePlotArea: {
                            minWidth: 800,
                            scrollPositionX: 0
                        },
                    },
                    xAxis: {
                        categories: ['Hangover','Anxiety','Panic Attack','Sweating','Heart palpitations','Shakes','Insomnia','Seizure','DTs'],
                    },
                    title: {
                        text: "In the last year, what symptoms have you experienced as a result of alcohol?"
                    },
                    series: [        
                        {
                        name: 'Yes',
                        data: [77,83,52,74,67,62,68,9,6]
                        },
                        {
                        name: 'No',
                        data: [11,5,36,14,21,26,20,79,82]
            
                        },
                        {
                        name: 'N/A',
                        data: [22,22,22,22,22,22,22,22,22]
                        },
                    ],
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                verticalAlign: 'bottom',
                                format: '{point.series.name}'
                            }
                        }
                    }
                }
            },
            Do_you_have_current_ongoing_medical_issue_s_as_a_result_of_current_or_past_drinking: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: "Do you have current ongoing medical issue/s as a result of current or past drinking?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [28,40,42]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__have_you_taken_something_to_help_liver_function__Milk_thistle__B_vitamins__etc_: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: " In the last year, have you taken something to help liver function? (Milk thistle, B vitamins, etc)"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [25,26,59]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            When_was_the_last_time_you_went_to_a_scheduled_medical_appointment__alcohol_related_or_otherwise_: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`A year or longer`,`Six months to a year`,`Three months to six months`,`A month to three months`,`Less than a month`],
                    },
                    title: {
                    text: " When was the last time you went to a scheduled medical appointment (alcohol related or otherwise)"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [22,9,15,12,24]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_alcohol_related_hospitalizations_have_you_had_in_the_last_year: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`,`3`,`4`,`6`],
                    },
                    title: {
                    text: "How many alcohol-related hospitalizations have you had in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [63,13,4,3,4,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_served_in_the_armed_forces_in_your_respective_country: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: " Have you served in the armed forces in your respective country?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [25,83,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_had_an_adverse_childhood_experience: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Yes`,`Maybe`],
                    },
                    title: {
                    text: "Have you had an adverse childhood experience?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [25,54,8]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_believe_it_has_contributed_to_increased_alcohol_use: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`Yes`,`Maybe`],
                    },
                    title: {
                    text: "Do you believe it has contributed to increased alcohol use?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [3,51,8]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_has_depression_affected_you: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "How much has depression affected you?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severe negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,5,16,31,35]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_do_you_believe_drinking_has_affected_this_depression: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                     categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                        text: "How much do you believe drinking has affected this depression?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severe negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [3,10,10,25,39]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_has_general_anxiety_affected_you: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                        categories: [`2`,`3`,`4`,`5`],
                    },
                    title: {
                        text: "How much has general anxiety affected you?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severe negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [8,15,26,39]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_do_you_believe_drinking_has_affected_this_general_anxiety: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                        categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                        text: "How much do you believe drinking has affected this general anxiety?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severe negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,11,15,19,41]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_has_social_anxiety_affected_you: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                        categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                        text: "How much has social anxiety affected you?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severe negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [6,13,17,19,32]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_much_do_you_believe_drinking_has_affected_this_social_anxiety: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                        type: 'bar'
                    },
                    xAxis: {
                        categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                        text: "How much do you believe drinking has affected this social anxiety?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severe negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [13,21,18,12,22]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_has_alcohol_affected_your_personal_relationships: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                        categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                        text: "How has alcohol affected your personal relationships?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severe negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [4,16,21,21,23]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_has_alcohol_affected_your_romantic_relationships: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                        categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                        text: "How has alcohol affected your romantic relationships?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severe negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [7,11,17,15,34]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_has_alcohol_affected_your_social_life: { 
                chartOptions: {
                    main: 'Medical',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                        categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                        text: "How has alcohol affected your social life?"
                    },
                    subtitle: {
                        text: '1 = No negative effects  2 = Slight negative effects  3 = Moderate negative effects  4 = Heavy negative effects  5 = Severe negative effects'
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [9,13,24,22,19]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            What_medical_disorders_have_you_been_diagnosed_with_from_a_doctor: { 
                chartOptions: {
                    main: 'Medical',
                    size: '12',
                    height: "auto",
                    chart: {
                        type: 'column',
                        scrollablePlotArea: {
                            minWidth: 2000,
                            scrollPositionX: 1
                        },
                    },
                    xAxis: {
                        categories: [`Anxiety`,`Depression`,`Generalized Anxiety Disorder `,`ADHD`,`Alcohol Use Disorder`,`Major Depressive Disorder`,`Anxiety `,`Borderline Personality Disorder`,`Depression `,`Panic Attack Disorder `,`PTSD`,`Bipolar`,`High Blood Pressure`,`OCD`,`Alcoholism`,`Arthritis `,`CPTSD`,`Fatty Liver`,`Gastritis`,`GERD`,`High Cholesterol`,`Hypertension`,`Psoriasis `,`Social Anxiety`,`Social anxiety`,`Adjustment Disorder`,`Alcoholic Hepatitis `,`Alcoholic Pancreatitis `,`Anemia`,`Antisocial personality Disorder`,`Asthma `,`Autism`,`Bi-Polar undetermined `,`Bipolar `,`Bipolar Depression `,`Borderline Personality `,`Cannabinoid dependence`,`Cervical and thoracic pain`,`Cervical Nerve pain`,`Charcot-Marie-Tooth Disease`,`Chronic Heartburn`,`Clinical depression `,`CRPS`,`Degenerative disc disease `,`Diabetes`,`Diabetes `,`Digestive issues`,`Elevated liver enzymes `,`Epilepsy`,`Gender dysphoria`,`High blood pressure `,`HPPD`,`Interstitial cystitis `,`LPR`,`Manic Depression`,`Multiple Sclerosis`,`Neuralgia parathestica `,`Neuropathy`,`Pancreatitis`,`PCOS`,`Persistent Genital Arousal Disorder`,`Post Traumatic Stress Disorder`,`Psychosis`,`Recurring depression`,`Rheumatoid Arthritis `,`Seasonal depression`,`Sensory integration disorder`,`Sleep apnea`,`Social anxiety `,`Social Anxiety Disorder `,`Substance abuse disorder`,`TMJ`,`Treatment Resistant depression. `,`White coat syndrome`],
                    },
                    title: {
                        text: "What medical disorders have you been diagnosed with from a doctor?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [14,14,12,9,7,6,5,5,5,5,5,4,3,3,2,2,2,2,2,2,2,2,2,2,2,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_receive_medication_for_any_disorders_listed_above: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: "Do you receive medication for any disorders listed above?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [51,22,37]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Has_drinking_worsened_the_symptoms_of_any_of_the_disorders_above: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`Not sure`,`No`,`Yes`],
                    },
                    title: {
                    text: " Has drinking worsened the symptoms of any of the disorders above?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [15,4,39]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_your_lifetime__have_you_felt_gender_dysphoria: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: "In your lifetime, have you felt gender dysphoria?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [24,76,10]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_believe_these_feelings_have_contributed_to_increased_alcohol_use: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: "Do you believe these feelings have contributed to increased alcohol use?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [100,4,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_ever_self_harmed__besides_drugs_alcohol_: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: " Have you ever self harmed (besides drugs/alcohol)?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [23,41,46]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: "In the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [64,25,21]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_have_a_therapist_or_otherwise_receive_regular_psychiatric_care_: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: "Do you have a therapist or otherwise receive regular psychiatric care? "
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [23,51,36]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_consider_it_helpful: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`Yes`,`No`,`Sometimes`],
                    },
                    title: {
                    text: "Do you consider it helpful?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [18,8,10]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Does_your_family_have_a_history_of_alcoholism: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: " Does your family have a history of alcoholism?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [24,19,67]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_hours_of_sleep_do_you_usually_get_on_average: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`3`,`4`,`5`,`6`,`7`,`8`,`9`,`10`,`12`],
                    },
                    title: {
                    text: "How many hours of sleep do you usually get on average?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [1,6,13,27,19,14,3,3,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__have_you_had_a_night_terror_: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: "In the last year, have you had a night terror? "
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [25,34,51]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Do_you_regularly_piss_the_bed_from_drinking: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`No`,`No, irregularly`,`Yes`],
                    },
                    title: {
                    text: " Do you regularly piss the bed from drinking?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [69,17,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_your_family_friends_staged_an_intervention_for_you_in_the_last_year: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: " Have your family/friends staged an intervention for you in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [26,71,13]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            In_the_last_year__have_you_tried_to_stop_drinking_: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`,`Yes, it was a forced decision (family, court, etc)`],
                    },
                    title: {
                    text: "In the last year, have you tried to stop drinking? "
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [2,21,59,4]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`,`6`,`7`,`10+`],
                    },
                    title: {
                    text: "How many times?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [6,11,17,7,2,2,1,17]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            What_was_the_longest_period_of_abstinance: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`Up to a day`,`A day to a week`,`A week to a month`,`A month to 3 months`,`3 months to 6 months`,`6 months to a year`],
                    },
                    title: {
                    text: "What was the longest period of abstinance?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [3,14,19,14,8,5]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Which_methods_programs_did_you_use__if_any__: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                        type: 'bar',
                        scrollablePlotArea: {
                            minHeight: 600,
                            scrollPositionX: 1
                        },
                    },
                    xAxis: {
                        categories: ['Cold Turkey','Tapering','/r/Dryalcoholics','None','/r/CA','AA/12 Step','/r/Stopdrinking','Inpatient/Outpatient Rehab','Moderation Management','Naltrexone/Vivitrol','SMART','Group Therapy','Campral','Antabuse','Court Ordered Rehabilitation','SLE (Sober Living Environment)','Recovery Support Services','Hotline/s','Baclofen','Benzos and bed rest and b vitamins','Coach.me','Psilocybin ','The Satanic Temple Sober Faction'],
                    },
                    title: {
                        text: "Which methods/programs did you use (if any)? "
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [31,23,22,19,17,12,11,9,8,7,6,5,5,3,2,2,1,1,1,1,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_been_to_a_facility_to_detox_from_alcohol_over_the_course_of_your_life: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`,`3`,`5`,`6`,`7`,`8`,`12`],
                    },
                    title: {
                    text: " How many times have you been to a facility to detox from alcohol over the course of your life?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [64,7,3,6,1,3,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_been_to_a_facility_to_detox_from_alcohol_in_the_last_year: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`,`3`,`6`],
                    },
                    title: {
                    text: " How many times have you been to a facility to detox from alcohol in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [14,5,2,2,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_been_to_inpatient_treatment_for_alcohol_use_over_the_course_of_your_life: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`,`6`,`7`],
                    },
                    title: {
                    text: " How many times have you been to inpatient treatment for alcohol use over the course of your life?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [4,4,1,1,1,1,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Have_you_ever_started_inpatient_treatment_for_alcohol_use: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`N/A`,`No`,`Yes`],
                    },
                    title: {
                    text: "Have you ever started inpatient treatment for alcohol use?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [22,71,17]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_started_inpatient_treatment_for_alcohol_use_in_the_last_year: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`,`4`],
                    },
                    title: {
                    text: " How many times have you started inpatient treatment for alcohol use in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [9,5,2,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            How_many_times_have_you_completed_inpatient_treatment_for_alcohol_use_in_the_last_year: { 
                chartOptions: {
                    main: 'Medical',
                    size: '4',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`0`,`1`,`2`,`4`],
                    },
                    title: {
                    text: " How many times have you completed inpatient treatment for alcohol use in the last year?"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [12,3,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
        
       }, //medical
       drug_Use: {
            Current_Use__Tobacco: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Tobacco"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [38,9,8,11,17]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Tobacco: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Tobacco"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [13,11,11,9,37]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Nicotine_Vaping: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Nicotine Vaping"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [54,7,1,4,17]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Nicotine_Vaping: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Nicotine Vaping"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [35,11,7,4,22]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Marijuana: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Marijuana"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [27,24,8,16,5]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Marijuana: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Marijuana"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [7,14,17,19,22]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Hash_or_other_marijuana_concentrates: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Hash or other marijuana concentrates"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [51,19,3,5,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Hash_or_other_marijuana_concentrates: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Hash or other marijuana concentrates"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [30,15,11,9,11]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Cocaine: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Cocaine"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [61,12,5,3,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Cocaine: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Cocaine"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [24,22,14,11,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Crack: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Crack"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [75,3,1,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Crack: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Crack"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [57,11,0,5,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Light_sleeping_medication__antihistamines__diphenhydramine__etc__Melatonin__Valarian_root__etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Light sleeping medication (antihistamines [diphenhydramine, etc, Melatonin, Valarian root, etc)"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [25,22,10,16,7]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Light_sleeping_medication__antihistamines__diphenhydramine__etc__Melatonin__Valarian_root__etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Light sleeping medication (antihistamines [diphenhydramine, etc, Melatonin, Valarian root, etc)"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [23,23,14,14,3]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Other_sleep_medications__Hypnotic_benzos__temazepan__nitrazepan__etc___Z_drugs__Lunesta__ambien__etc___etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Other sleep medications: Hypnotic benzos (temazepan, nitrazepan, etc), Z-drugs (Lunesta, ambien, etc), etc."
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [64,6,1,4,5]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Prescription_amphetamines: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Prescription amphetamines"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [45,13,6,4,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Other_sleep_medications__Hypnotic_benzos__temazepan__nitrazepan__etc___Z_drugs__Lunesta__ambien__etc___etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Other sleep medications: Hypnotic benzos (temazepan, nitrazepan, etc), Z-drugs (Lunesta, ambien, etc), etc."
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [42,22,5,3,4]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Prescription_amphetamines: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Prescription amphetamines"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [68,4,3,2,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Methamphetamine: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Methamphetamine"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [75,2,1,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Methamphetamine: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Methamphetamine"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [58,4,4,4,4]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__MDMA_estacy: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) MDMA/estacy"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [70,6,3,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___MDMA_estacy: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) MDMA/estacy"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [39,22,11,3,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Mushrooms: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Mushrooms"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [50,20,9,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Mushrooms: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Mushrooms"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [30,26,13,6,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__LSD: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) LSD"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [68,10,1,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___LSD: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) LSD"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [42,18,9,7,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Heroin_Opium: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Heroin/Opium"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [76,3,0,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Heroin_Opium: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Heroin/Opium"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [62,4,3,1,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Kratom: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Kratom"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [62,6,3,4,5]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Kratom: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Kratom"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [53,6,2,7,8]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Kava: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Kava"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [73,4,1,1,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Kava: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Kava"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [68,5,1,1,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Gabanergics__Kratom__Phenibut__etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Gabanergics (Kratom, Phenibut, etc)"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [65,7,2,2,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Gabanergics__Kratom__Phenibut__etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Gabanergics (Kratom, Phenibut, etc)"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [59,6,2,3,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Prescription_opiates: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Prescription opiates"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [64,12,0,1,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Prescription_opiates: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Prescription opiates"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [48,15,6,4,5]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Research_chemicals__2c_i__methylone__etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Research chemicals (2c-i, methylone, etc)"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [72,5,0,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Research_chemicals__2c_i__methylone__etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Research chemicals (2c-i, methylone, etc)"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [65,6,1,2,2]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Anxiolytic_Benzodiazapines__Valium__Klonopin__Xanax__Librium__Ativan__etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Anxiolytic Benzodiazapines (Valium, Klonopin, Xanax, Librium, Ativan, etc)"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [55,12,2,5,6]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Anxiolytic_Benzodiazapines__Valium__Klonopin__Xanax__Librium__Ativan__etc_: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Anxiolytic Benzodiazapines (Valium, Klonopin, Xanax, Librium, Ativan, etc)"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [34,15,7,9,12]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__GHB_GBL: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) GHB/GBL"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [73,3,0,1,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___GHB_GBL: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) GHB/GBL"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [68,6,2,1,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Amyl_Nitrate_poppers: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Amyl Nitrate/poppers"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [72,5,1,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Amyl_Nitrate_poppers: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Amyl Nitrate/poppers"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [66,8,2,1,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Ketamine: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Ketamine"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [70,4,3,1,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Ketamine: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Ketamine"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [56,10,6,4,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__DXM_cough_syrup: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) DXM/cough syrup"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [69,7,0,1,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___DXM_cough_syrup: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) DXM/cough syrup"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [56,10,5,4,1]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__Nitrous_oxide: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) Nitrous oxide"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [70,7,1,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___Nitrous_oxide: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) Nitrous oxide"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [53,13,5,5,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Current_Use__DMT: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Current Use) DMT"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [77,1,0,0,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },
            Past_Use___DMT: { 
                chartOptions: {
                    main: 'Drug_Use',
                    size: '6',
                    height: "auto",
                    chart: {
                    type: 'bar'
                    },
                    xAxis: {
                    categories: [`1`,`2`,`3`,`4`,`5`],
                    },
                    title: {
                    text: "(Past Use) DMT"
                    },
                    subtitle: {
                    text: "1 = No Use 2 = Rarely 3 = Social use 4 = Habitual use 5 = Addictive use"
                    },
                    series: [
                    {
                        name: 'Count',
                        data: [66,8,1,2,0]
                    }
                    ],
                    legend: {
                    enabled: false
                    }
                }
            },   
            
       } //drug use
   }] //charts
} //export
