import Vue from 'vue'
import App from './App.vue'
import { mdiMenu} from '@mdi/font/css/materialdesignicons.css'
import vuetify from './plugins/vuetify'
import HighchartsVue from 'highcharts-vue'
import Highcharts from "highcharts";
import mapInit from "highcharts/modules/map";
import WorldMap from "@highcharts/map-collection/custom/world.geo.json";
import NorthAmerica from "@highcharts/map-collection/custom/usa-and-canada.geo.json";
import sunset from "highcharts/themes/sunset";
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'

Vue.use(PiniaVuePlugin)
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

mapInit(Highcharts);
Highcharts.maps["myMapName"] = WorldMap;
Highcharts.maps["NaCan"] = NorthAmerica;
Vue.use(HighchartsVue)
Highcharts.setOptions({
  plotOptions: {
    series: {
        dataLabels: {
            enabled: true
        }
    }
  }
});
sunset(Highcharts);
const pinia = createPinia();

new Vue({
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')
