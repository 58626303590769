<template>
    <v-container>
      <v-row fill-height>
        <v-col v-for="(item,obj) of currentCat" 
          :key="obj" 
          :cols="12" 
          :md="item.chartOptions.size">
            <v-lazy>
              <v-card
                v-model="obj.isActive" :options="{
                    threshold: .5
                }"
                class="fill-height">
                <highcharts 
                  class="hc"
                  :options="item.chartOptions"
                  :constructor-type="item.chartOptions.mapChart"
                  :class="item.chartOptions.class"
                  ref="chart">
                </highcharts>
              </v-card>
            </v-lazy>
        </v-col>
      </v-row>
      <v-pagination
        v-model="ds.currentPage"
        :length="ds.catAmount"
        @input="$vuetify.goTo(0);"
        class="ma-4"
        >
      </v-pagination>
    </v-container>
</template>

<script>
    import { useDataStore } from '../stores/currentData.js'

    export default {
        name: 'chartContainer',
        computed: {
          currentCat(){
            let currentYear = this.ds.allYears[this.ds.year];
            let currentCatName = currentYear.categories[this.ds.currentPage-1]; 
            return currentYear.charts[0][currentCatName];
          } /* charts: [{ 
            demographics: {name: chartOptions, etc}
            }] 
            */
        },
        data(){
          return {
            ds: useDataStore(),
          } 
        }
    }
</script>
